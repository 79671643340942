import type { Fetchable } from "@/services/utilities/fetchable";

type SwitchFetchableProps<Subject> = {
  readonly fetchable: Fetchable<Subject>;
  readonly renderInitial?: () => JSX.Element;
  readonly renderPending?: () => JSX.Element;
  readonly renderSucceeded?: (value: Subject | undefined) => JSX.Element;
  readonly renderFailed?: (errorMessage: string) => JSX.Element | null;
  readonly renderDefault?: () => JSX.Element;
};

export function SwitchFetchable<Subject>({
  fetchable,
  renderInitial,
  renderPending,
  renderSucceeded,
  renderFailed,
  renderDefault,
}: SwitchFetchableProps<Subject>) {
  switch (fetchable.$class) {
    case "initial":
      return renderInitial?.() ?? renderDefault?.() ?? null;
    case "pending":
      return renderPending?.() ?? renderDefault?.() ?? null;
    case "succeeded":
      return renderSucceeded?.(fetchable.value) ?? renderDefault?.() ?? null;
    case "failed":
      return (
        renderFailed?.(fetchable.errorMessage) ?? renderDefault?.() ?? null
      );
  }
}
