import type { Bcp47Locale, Locale } from "./locale.types";

const bcp47Locales: Readonly<Record<Locale, Bcp47Locale>> = {
  da: "da-DK",
  de: "de-DE",
  en: "en-GB",
  no: "no-NO",
  sv: "sv-SE",
};

export function mapToBcp47Locale(locale: Locale): Bcp47Locale {
  return bcp47Locales[locale];
}
