"use client";

import { useEffect, useState } from "react";
import { useChronology } from "@/contexts/chronology/chronology.context";
type RecentJobChangesStartDateProps = {
  readonly date: `${number}-${number}-${number}`;
};
export function RecentJobChangesStartDate({
  date
}: RecentJobChangesStartDateProps) {
  const {
    toShortDateString
  } = useChronology();
  const [shortDate, setShortDate] = useState<string | null>(null);
  useEffect(() => {
    // Please note that because we are using `new Date()` here, we have
    // to wrap it in a `useEffect` hook to avoid hydration issues.
    // The date we receive does not contain time zone information
    // but as we only display the date, this is not a problem.
    setShortDate(toShortDateString(new Date(date)));
  }, [toShortDateString, date]);
  if (shortDate === null) return null;
  return <time className="text-xs font-theme-bold" dateTime={date} data-sentry-component="RecentJobChangesStartDate" data-sentry-source-file="RecentJobChangesStartDate.component.tsx">
      {shortDate}
    </time>;
}