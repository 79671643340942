import type { SelfDescribingJson } from "@snowplow/browser-tracker";

type SnowplowJobPayloadProps = {
  readonly jpmuid: string;
  readonly jobAdId: number;
  readonly placement: string;
  readonly isInView: boolean;
};

export function snowplowJobPayload({
  placement,
  jobAdId,
  jpmuid,
}: SnowplowJobPayloadProps): ReadonlyArray<SelfDescribingJson> | null {
  const jobTrackingContext = "website";
  const jobPayload = [
    {
      data: {
        context: jobTrackingContext,
        jobAdIds: [jobAdId],
        jpmuid,
        placement,
      },
      schema: "iglu:dk.jyllands-posten/jobad_impression/jsonschema/4-0-0",
    },
  ];
  return jobPayload;
}
