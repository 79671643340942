"use client";

import { useRef } from "react";
import { useIntersectionObserver } from "usehooks-ts";
import { useJpmuid } from "@/hooks/tracking/jpmuid/use-jpmuid.hook";
import { snowplowJobPayload } from "@/hooks/tracking/snowplow/snowplow-payload/snowplow-job-payload";
import { useSnowplowInViewTracking } from "@/hooks/tracking/snowplow/use-snowplow-in-view-event.hook";

type UseTrackJobAdInviewProps = {
  readonly jobAdId: number;
  readonly placement: string;
};

export function useTrackJobAdInview({
  jobAdId,
  placement,
}: UseTrackJobAdInviewProps) {
  const ref = useRef<HTMLAnchorElement | null>(null);
  const entry = useIntersectionObserver(ref, {
    threshold: 0.8, // Track when the element is 80% visible in the viewport
  });
  const isInView: boolean = entry?.isIntersecting ?? false;

  const { jpmuid } = useJpmuid();
  const jobContext = snowplowJobPayload({
    isInView,
    jobAdId,
    jpmuid,
    placement,
  });

  useSnowplowInViewTracking({
    action: "Impression",
    category: "JobAd",
    context: jobContext,
    hasTrackingEnabled: true,
    isInView,
  });

  return ref;
}
