"use client";

import clsx from "clsx";
import Image from "next/image";
import { useImageConfiguration } from "@/contexts/image-configuration/image-configuration.context";
import { tailwindScreenSize } from "@/utilities/get-tailwind-config";
import { imageLoader } from "@/utilities/image-loader";
import { Translation } from "../Translation/Translation.component";
import type { RecentJobChangesModel } from "./models/recent-job-changes.types";
import { RecentJobChangesStartDate } from "./RecentJobChangesStartDate.component";
type RecentJobChangesProps = {
  readonly className?: string;
  readonly items: ReadonlyArray<RecentJobChangesModel>;
};
export function RecentJobChanges({
  className,
  items
}: RecentJobChangesProps) {
  const {
    serverUrl
  } = useImageConfiguration();
  return <article className={clsx(className, "h-full px-2 py-3")} data-sentry-component="RecentJobChanges" data-sentry-source-file="RecentJobChanges.component.tsx">
      <h1 className="mb-4 flex flex-col font-theme-bold uppercase">
        <Translation model={{
        da: "Seneste jobskifte",
        de: "Jüngste Karrierewechsel",
        en: "Job changes",
        no: "Siste jobbskifter",
        sv: "Senaste jobbytet"
      }} data-sentry-element="Translation" data-sentry-source-file="RecentJobChanges.component.tsx" />
      </h1>
      <ul className="space-y-4">
        {items.map(item => <li className="relative grid grid-cols-12 gap-2" key={item.id}>
            {item.image !== null ? <div className={clsx("relative col-span-full hidden aspect-4/3 w-full sm:col-span-3 sm:block")}>
                <Image alt={item.image.alternativeText} className="bg-whisper object-cover object-center" draggable={false} loader={imageLoader(serverUrl)} sizes={`(max-width: ${tailwindScreenSize.md}) 33vw, 140px`} src={item.image.aspectRatio4By3} fill />
              </div> : null}
            <div className={clsx("col-span-full break-words", item.image !== null && "sm:col-span-9")}>
              {item.startDate !== null ? <RecentJobChangesStartDate date={item.startDate} /> : null}
              <h2 className="block grow font-heading text-lg font-theme-bold lg:text-xl">
                {item.name}
              </h2>
              <div className="mt-2 text-sm">
                {item.newJobTitle !== null ? <div>
                    <span className="font-theme-bold">
                      <Translation model={{
                  da: "Ny titel:",
                  de: "Neuer Titel:",
                  en: "New position:",
                  no: "Tiltrer i stilling som:",
                  sv: "Ny titel:"
                }} />
                    </span>{" "}
                    {item.newJobTitle}
                  </div> : null}
                {item.newEmployer !== null ? <div>
                    <span className="font-theme-bold">
                      <Translation model={{
                  da: "Ny arbejdsgiver:",
                  de: "Neuer Arbeitgeber:",
                  en: "New employer:",
                  no: "Ny arbeidsgiver:",
                  sv: "Ny arbetsgivare:"
                }} />
                    </span>{" "}
                    {item.newEmployer}
                  </div> : null}
              </div>
            </div>
          </li>)}
      </ul>
    </article>;
}